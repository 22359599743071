<template>
    <b-modal title="Подтвердите действие" no-close-on-backdrop no-close-on-esc cancel-title="Отмена" ok-title="Подтвердить" :visible="visible" @cancel="handleCancel" @close="handleCancel" @ok="handleAccept">
        <p>Вы действительно хотите подтвердить исполнение запроса на возврат средств?</p>
        <slot></slot>
    </b-modal>
</template>

<script>
export default {
    name: 'refund-request-accept-modal',
    props: ['visible'],
    methods: {
        handleAccept(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.$emit('accept');
        },
        handleCancel(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.$emit('cancel');
        },
    },
};
</script>

<style></style>
