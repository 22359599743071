<template>
    <div>
        <b-button size="sm" variant="primary" @click="show = true">Пополнить счет</b-button>
        <b-modal v-model="show" :title="'Пополнение счета ' + organization_name" no-close-on-backdrop no-close-on-esc @cancel="handleCancel" @close="handleCancel" @ok="handleReplenish">
            <currency-input v-model="amount" :v="$v.amount" label="Сумма пополнения" />
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="default" @click="cancel()">Отмена</b-button>
                <b-button :disabled="$v.$invalid" variant="success" @click="ok()">Пополнить счет</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import CurrencyInput from '@/elements/CurrencyInput.vue';
import { minValue, required } from 'vuelidate/lib/validators';

export default {
    name: 'AccountReplenishButton',
    components: {
        CurrencyInput,
    },
    props: {
        organization_name: {
            required: true,
            type: String,
        },
        organization_id: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            show: false,
            amount: 0,
        };
    },
    validations: {
        amount: {
            required,
            minValue: minValue(1),
        },
    },
    methods: {
        handleCancel() {
            this.amount = 0;
            this.show = false;
        },
        async handleReplenish(bvModalEvent) {
            this.isDataSending = true;
            bvModalEvent.preventDefault();
            if (await this.$api.organizationAccounts.replenish(this.organization_id, this.amount)) {
                await this.$store.dispatch('showSuccessToast', `Лицевой счет ${this.organization_name} пополнен на ${this.$formatPrice(this.amount)} сом.`);
                this.handleCancel();
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
    },
};
</script>
