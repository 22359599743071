<template>
    <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block w-100">
        <b-btn size="sm" class="mb-1 w-100" variant="danger" @click="formReject">Отклонить</b-btn>
        <refund-request-reject-modal :visible="showRejectModal" :organization_inn="value.organization_inn" :organization_name="value.organization_name" :refund_amount="value.amount" @cancel="handleModalCancel" @accept="handleRejectModal">
            <p>
                <b>Название организации:</b> {{ value.organization_name }}<br />
                <b>ИНН:</b> {{ value.organization_inn }}<br />
                <b>Сумма возврата:</b> {{ $formatPrice(value.requested_amount) }} {{ value.requested_currency.title }} ({{ $formatPrice(value.amount) }} Сом)
            </p>
        </refund-request-reject-modal>
    </b-overlay>
</template>

<script>
import RefundRequestRejectModal from '@/components/account/refund-request-reject-modal';
import store from '@/store';

export default {
    name: 'refund-request-direct-reject-button',
    components: { RefundRequestRejectModal },
    props: ['value'],
    data() {
        return {
            isDataSending: false,
            showRejectModal: false,
        };
    },
    methods: {
        formReject() {
            this.showRejectModal = true;
        },
        async handleRejectModal(rejectComment) {
            this.isDataSending = true;
            if (await this.$api.refundRequests.reject(this.value.id, rejectComment)) {
                await store.dispatch('showSuccessToast', 'Запрос на возврат средств успешно отклонен').then(() => {});
                this.handleModalCancel();
            }
            this.isDataSending = false;
        },
        handleModalCancel() {
            this.showRejectModal = false;
            this.isDataSending = false;
            this.$emit('close');
        },
    },
};
</script>
