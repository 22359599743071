<template>
    <b-modal title="Подтвердите действие" no-close-on-backdrop no-close-on-esc cancel-title="Отмена" ok-title="Подтвердить" :visible="visible" @cancel="handleCancel" @close="handleCancel" @ok="handleAccept">
        <p>Вы действительно хотите <b>ОТКЛОНИТЬ</b> запрос на возврат средств?</p>
        <slot></slot>
        <form-row-edit-textarea v-model="rejectComment" label="Комментарий для поставщика с описанием причины отклонения запроса на возврат средств" :label-cols-lg="null" :label-cols-sm="null" :v="$v.rejectComment" />
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-button variant="default" @click="cancel()">Отмена</b-button>
            <b-button :disabled="$v.$invalid" variant="danger" @click="ok()">Отклонить запрос</b-button>
        </template>
    </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'refund-request-reject-modal',
    props: ['visible'],
    data() {
        return {
            rejectComment: '',
        };
    },
    validations() {
        return {
            rejectComment: { required },
        };
    },
    methods: {
        handleAccept(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.$emit('accept', this.rejectComment);
            this.rejectComment = '';
        },
        handleCancel(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.rejectComment = '';
            this.$emit('cancel');
        },
    },
};
</script>

<style></style>
