<template>
    <div>
        <h4 class="font-weight-bold py-3 mb-4">Финансовые операции</h4>

        <loading-card v-show="loading"></loading-card>

        <b-card v-show="!loading" no-body>
            <b-card-body class="pb-4">
                <vuetable ref="vuetable" :api-url="apiUrl" :css="vuetable2Config.table" :fields="columns" :http-fetch="fetchData" :load-on-start="false" :per-page="perPage" data-path="data" no-data-template="Нет данных" pagination-path="meta" @vuetable:pagination-data="onPaginationData">
                    <template #created_at="props">
                        {{ getDateTimeFormat(props.rowData.created_at) }}
                    </template>
                    <template #amount="props">
                        {{ $formatPrice(props.rowData.amount) }}
                    </template>
                    <template #user_name="props">
                        <template v-if="!props.rowData.authenticatable">Система</template>
                        <template v-else> {{ props.rowData.authenticatable.name }} ({{ props.rowData.authenticatable.type === 'admin' ? 'Администратор' : 'Пользователь' }}) </template>
                    </template>
                    <template #operation="props">
                        <strong>{{ props.rowData.operation.title }}&nbsp;</strong>
                        <template v-if="props.rowData.accountable && props.rowData.accountable.type === 'competitive_purchase'">
                            (Извещение №
                            <router-link :to="{ name: 'CompetitivePurchaseShow', params: { id: props.rowData.accountable.id } }" target="_blank">
                                {{ props.rowData.accountable.reg_number }}
                            </router-link>
                            )
                        </template>
                        <template v-if="props.rowData.accountable && props.rowData.accountable.type === 'simple_purchase'">
                            (Извещение №
                            <router-link :to="{ name: 'SimplePurchaseShow', params: { id: props.rowData.accountable.id } }" target="_blank">
                                {{ props.rowData.accountable.reg_number }}
                            </router-link>
                            )
                        </template>
                        <template v-if="props.rowData.accountable && props.rowData.accountable.type === 'direct_purchase'">
                            (Извещение №
                            <router-link :to="{ name: 'DirectPurchaseShow', params: { id: props.rowData.accountable.id } }" target="_blank">
                                {{ props.rowData.accountable.reg_number }}
                            </router-link>
                            )
                        </template>
                        <template v-if="props.rowData.accountable && props.rowData.accountable.type === 'refund_request'"> (Запрос на возврат средств № {{ props.rowData.accountable.reg_number }})</template>
                    </template>
                </vuetable>
                <div class="float-right d-flex" style="margin-top: 10px">
                    <vuetable-pagination ref="pagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onChangePage" />
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
export default {
    name: 'AccountShow',
    metaInfo: {
        title: 'Лицевые счета - Просмотр',
    },
    data() {
        return {
            columns: [
                { name: 'created_at', title: 'Дата/время' },
                { name: 'operation', title: 'Операция' },
                { name: 'amount', title: 'Сумма, руб.', dataClass: 'text-right text-nowrap' },
                { name: 'user_name', title: 'Пользователь / Система' },
            ],
            paginationInfo: {},
            loading: true,
            perPage: 15,
        };
    },
    mounted() {
        this.$refs.vuetable.refresh().then(() => {
            this.$refs.vuetable.changePage(this.paginationInfo.page);
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        fetchData(apiUrl, httpOptions) {
            return this.$http.get(apiUrl, httpOptions);
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
    },
    computed: {
        apiUrl: function () {
            return '/organization_accounts/' + this.$route.params.id;
        },
    },
};
</script>
