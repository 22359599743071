<template>
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <b-card class="mb-4">
                <div class="align-items-center">
                    <div class="ml-3">
                        <div class="text-muted small">Депозит</div>
                        <div class="text-large align-right">{{ $formatPrice(summary.total_sum) }} руб.</div>
                    </div>
                </div>
            </b-card>
        </div>
        <div class="col-sm-12 col-md-4">
            <b-card class="mb-4">
                <div class="align-items-center">
                    <div class="ml-3">
                        <div class="text-muted small">Доступно</div>
                        <div class="text-large align-right">{{ $formatPrice(summary.available_sum) }} руб.</div>
                    </div>
                </div>
            </b-card>
        </div>
        <div class="col-sm-12 col-md-4">
            <b-card class="mb-4">
                <div class="align-items-center">
                    <div class="ml-3">
                        <div class="text-muted small">Заблокировано</div>
                        <div class="text-large align-right">{{ $formatPrice(summary.blocked_sum) }} руб.</div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountSummary',
    data() {
        return {
            summary: {
                total_sum: 0,
                available_sum: 0,
                blocked_sum: 0,
            },
        };
    },
    created() {
        this.fillData();
    },
    methods: {
        async fillData() {
            this.summary = await this.$api.organizationAccounts.summary();
        },
    },
};
</script>
