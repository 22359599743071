<template>
    <div>
        <loading-card v-show="loading"></loading-card>

        <div v-show="!loading">
            <div>
                <b-row class="mb-4">
                    <filter-text label="Краткое наименование" placeholder="Введите краткое наименование" v-model="filter.name" @input="onFilterChange" />
                    <filter-text label="ИНН" placeholder="Введите ИНН" v-model="filter.inn" @input="onFilterChange" />
                </b-row>
            </div>
            <div>
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :fields="columns"
                        :per-page="perPage"
                        :css="vuetable2Config.table"
                        :sort-order="paginationInfo.sortOrder"
                        :load-on-start="false"
                        :query-params="tableQueryParams"
                        :append-params="filter"
                        :http-fetch="fetchData"
                        @vuetable:pagination-data="onPaginationData"
                        api-url="/organization_accounts"
                        data-path="data"
                        pagination-path="meta"
                        no-data-template="Нет данных">
                        <template #name="props">
                            <router-link :to="{ name: 'AccountShow', params: { id: props.rowData.id } }">{{ props.rowData.name }}</router-link>
                        </template>
                        <template #inn="props">
                            <template v-if="props.rowData.inn">{{ props.rowData.inn }}</template>
                            <template v-else>&mdash;</template>
                        </template>
                        <template #reg_number="props">
                            <template v-if="props.rowData.reg_number">{{ props.rowData.reg_number }}</template>
                            <template v-else>&mdash;</template>
                        </template>
                        <template #account.available="props">
                            {{ $formatPrice(props.rowData.account.available) }}
                        </template>
                        <template #account.blocked="props">
                            {{ $formatPrice(props.rowData.account.blocked) }}
                        </template>
                        <template #account.total="props">
                            {{ $formatPrice(props.rowData.account.total) }}
                        </template>
                        <template #account.updated_at="props">
                            {{ getDateTimeFormatSeconds(props.rowData.account.updated_at) }}
                        </template>
                        <template #actions="props">
                            <account-replenish-button :organization_id="props.rowData.id" :organization_name="props.rowData.name" @refresh="onFilterChange" />
                        </template>
                    </vuetable>
                </div>
                <div style="margin-top: 10px" class="float-right d-flex">
                    <vuetable-pagination ref="pagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onChangePage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccountReplenishButton from '@/components/account/account-replenish-button.vue';

export default {
    name: 'AccountList',
    components: { AccountReplenishButton },
    data() {
        return {
            isDataSending: false,
            file: '',
            columns: [
                { name: 'name', title: 'Наименование организации', sortField: true },
                { name: 'inn', title: 'ИНН', sortField: true, width: '150px' },
                { name: 'reg_number', title: 'Рег.номер', sortField: true, width: '200px' },
                { name: 'account.total', title: 'Депозит', sortField: true, width: '150px', dataClass: 'text-right text-nowrap' },
                { name: 'account.available', title: 'Доступно', sortField: true, width: '150px', dataClass: 'text-right text-nowrap' },
                { name: 'account.blocked', title: 'Заблокировано', sortField: true, width: '150px', dataClass: 'text-right text-nowrap' },
                { name: 'account.updated_at', title: 'Дата и время последней операции', sortField: true, width: '100px' },
                { name: 'actions', title: 'Действия', width: '120px', dataClass: 'text-right' },
            ],
            moneyAddModal: {
                show: false,
                organization_id: null,
                organization_name: null,
                amount: 0,
            },
            filter: this.$store.state.accounts.filter,
            paginationInfo: this.$store.state.accounts.pagination,
            loading: true,
            perPage: 15,
        };
    },
    mounted() {
        this.$refs.vuetable.refresh().then(() => {
            this.$refs.vuetable.changePage(this.paginationInfo.page);
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        fetchData(apiUrl, httpOptions) {
            return this.$http.get(apiUrl, httpOptions);
        },
        tableQueryParams(sortOrder, currentPage, perPage) {
            this.paginationInfo.page = currentPage;
            this.$store.commit('accounts/updatePagination', this.paginationInfo);
            return {
                sortField: sortOrder[0].field,
                sortOrder: sortOrder[0].direction,
                page: currentPage,
                per_page: perPage,
            };
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.paginationInfo.page = page;
            this.$refs.vuetable.changePage(page);
        },
        onFilterChange() {
            this.$store.commit('accounts/updateFilter', this.filter);
            this.$refs.vuetable.refresh();
            this.$emit('refresh');
        },
    },
};
</script>

<style scoped>
input[type='file'] {
    position: absolute;
    top: -500px;
    display: none;
}
</style>
