<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label="label" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <b-form-input ref="inputRef" v-model="formattedValue" class="form-control fs-14" :class="v !== null ? (state ? 'is-valid' : 'is-invalid') : ''" />
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';
import { watch } from 'vue';

export default {
    name: 'CurrencyInput',
    props: {
        value: {
            type: Number,
            default: null,
        },
        label: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: '',
        },
        v: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        limit: {
            type: Number,
            default: null,
        },
    },
    setup(props) {
        const { inputRef, formattedValue, setValue } = useCurrencyInput({ currency: 'KGS', currencyDisplay: 'hidden', precision: 2, locale: 'ru-KG', valueScaling: 'precision' });

        watch(
            () => props.value,
            (value) => {
                setValue(value);
            }
        );

        return { inputRef, formattedValue };
    },
    computed: {
        state() {
            return this.v !== null ? !this.v.$invalid && !this.errorMessage : null;
        },
        errorMessage() {
            if (this.v && !this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            if (this.v && this.value === 0) return 'Поле "' + this.label + '" должно быть больше 0';
            if (this.limit !== null && this.value > this.limit) return 'Поле "' + this.label + '" превышает доступный лимит в ' + this.limit + ' руб.';
            return '';
        },
    },
};
</script>
