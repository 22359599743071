<template>
    <div>
        <h4 class="d-flex justify-content-between align-items-center font-weight-bold py-3 mb-4">Лицевые счета и комиссия</h4>
        <b-card no-body>
            <b-tabs card>
                <b-tab title="Лицевые счета">
                    <account-summary ref="accountSummary" />
                    <account-list ref="accountList" @refresh="refresh" />
                </b-tab>
                <b-tab title="Комиссия">
                    <!--                    <accounting-commission></accounting-commission>-->
                </b-tab>
                <b-tab title="Запросы на возврат средств">
                    <account-refund-request-list ref="accountRefundRequestList" @refresh="refresh" />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import AccountList from '@/components/account/account-list.vue';
// import AccountingCommission from '@/components/account/account-commission.vue';
import AccountSummary from '@/components/account/account-summary.vue';
import AccountRefundRequestList from '@/components/account/account-refund-request-list.vue';

export default {
    name: 'Account',
    metaInfo: {
        title: 'Лицевые счета и комиссия',
    },
    components: {
        AccountRefundRequestList,
        AccountSummary,
        // AccountingCommission,
        AccountList,
    },
    methods: {
        refresh() {
            this.$refs.accountSummary.fillData();
            this.$refs.accountList.$refs.vuetable.refresh();
        },
    },
};
</script>
