<template>
    <div>
        <loading-card v-show="loading"></loading-card>

        <div v-show="!loading">
            <div>
                <b-row class="mb-4">
                    <filter-text label="Рег.номер" placeholder="Введите рег.номер запроса" v-model="filter.reg_number" @input="onFilterChange" />
                    <filter-text label="Организация (название / ИНН)" placeholder="Введите название или ИНН организации" v-model="filter.organization_name" @input="onFilterChange" />
                </b-row>
            </div>
            <div>
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :fields="columns"
                        :per-page="perPage"
                        :css="vuetable2Config.table"
                        :sort-order="paginationInfo.sortOrder"
                        :load-on-start="false"
                        :query-params="tableQueryParams"
                        :append-params="filter"
                        :http-fetch="fetchData"
                        @vuetable:pagination-data="onPaginationData"
                        api-url="/refund_requests"
                        data-path="data"
                        pagination-path="meta"
                        no-data-template="Нет данных">
                        <template #organization_id="props">
                            <router-link :to="{ name: 'OrganizationEdit', params: { id: props.rowData.organization_id } }" target="_blank">{{ props.rowData.organization_name }}</router-link>
                        </template>
                        <template #amount="props">
                            <div class="text-right">
                                {{ $formatPrice(props.rowData.amount) }}
                            </div>
                        </template>
                        <template #status="props">
                            <strong>{{ props.rowData.status.title }}</strong>
                            <br />
                            <p v-if="props.rowData.reject_comment"><strong>Причина отклонения: </strong>{{ props.rowData.reject_comment }}</p>
                        </template>
                        <template #created_at="props">
                            {{ getDateTimeFormatSeconds(props.rowData.created_at) }}
                        </template>
                        <template #stored_document="props">
                            <a :href="$getAttachmentDownloadLink(props.rowData.attachment.id)">{{ props.rowData.attachment.name }}</a>
                        </template>
                        <template #actions="props" v-if="userRoleIsPermitted([AdminRole.SUPER])">
                            <refund-request-direct-accept-button :value="props.rowData" @close="refresh" v-if="props.rowData.status.id === 'new'" />
                            <refund-request-direct-reject-button :value="props.rowData" @close="refresh" v-if="props.rowData.status.id === 'new'" />
                        </template>
                    </vuetable>
                </div>
                <div style="margin-top: 10px" class="float-right d-flex">
                    <vuetable-pagination ref="pagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onChangePage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { RefundRequestStatus } from '@/const';
import RefundRequestDirectAcceptButton from '@/components/account/refund-request-direct-accept-button';
import RefundRequestDirectRejectButton from '@/components/account/refund-request-direct-reject-button';

export default {
    name: 'account-refund-request-list',
    components: { RefundRequestDirectAcceptButton, RefundRequestDirectRejectButton },
    metaInfo: {
        title: 'Запросы на возврат средств',
    },
    data() {
        return {
            columns: [
                { name: 'id', sortField: 'id', title: '#' },
                { name: 'reg_number', sortField: 'reg_number', title: 'Рег.номер' },
                { name: 'organization_id', sortField: 'organization_id', title: 'Название организации' },
                { name: 'organization_inn', sortField: 'organization_id', title: 'ИНН организации' },
                { name: 'stored_document', title: 'Реквизиты' },
                { name: 'amount', sortField: 'amount', title: 'Сумма, руб.' },
                { name: 'status', title: 'Статус' },
                { name: 'created_at', sortField: 'created_at', title: 'Время создания', width: '140px' },
                { name: 'actions', title: 'Действия', width: '100px' },
            ],
            filter: this.$store.state.refundRequests.filter,
            paginationInfo: this.$store.state.refundRequests.pagination,
            loading: true,
            perPage: 20,
        };
    },
    async mounted() {
        await this.refresh();
        await this.$refs.vuetable.changePage(this.paginationInfo.page);
        this.loading = false;
    },
    methods: {
        refresh() {
            this.$refs.vuetable.refresh();
            this.$emit('refresh');
        },
        fetchData(apiUrl, httpOptions) {
            return this.$http.get(apiUrl, httpOptions);
        },
        tableQueryParams(sortOrder, currentPage, perPage) {
            this.paginationInfo.page = currentPage;
            this.$store.commit('refundRequests/updatePagination', this.paginationInfo);
            return {
                sortField: sortOrder[0].sortField,
                sortOrder: sortOrder[0].direction,
                page: currentPage,
                per_page: perPage,
            };
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.paginationInfo.page = page;
            this.$refs.vuetable.changePage(page);
        },
        onFilterChange() {
            this.$store.commit('refundRequests/updateFilter', this.filter);
            this.$refs.vuetable.refresh();
            this.$emit('refresh');
        },
        getRefundRequestStatusName(id) {
            return RefundRequestStatus.find((i) => i.id === id)?.name;
        },
    },
};
</script>
