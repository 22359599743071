<template>
    <b-overlay :show="isDataSending" class="d-inline-block w-100" opacity="0.6" rounded spinner-small spinner-variant="primary">
        <b-btn class="mb-1 w-100" size="sm" variant="success" @click="formAccept">Исполнен</b-btn>
        <refund-request-accept-modal :visible="showAcceptModal" @accept="handleAcceptModal" @cancel="handleModalCancel">
            <p>
                <b>Название организации:</b> {{ value.organization_name }}<br />
                <b>ИНН:</b> {{ value.organization_inn }}<br />
                <b>Сумма возврата:</b> {{ $formatPrice(value.requested_amount) }} {{ value.requested_currency.title }} ({{ $formatPrice(value.amount) }} Сом)
            </p>
        </refund-request-accept-modal>
    </b-overlay>
</template>

<script>
import RefundRequestAcceptModal from '@/components/account/refund-request-accept-modal';
import store from '@/store';

export default {
    name: 'refund-request-direct-accept-button',
    components: { RefundRequestAcceptModal },
    props: ['value'],
    data() {
        return {
            isDataSending: false,
            showAcceptModal: false,
        };
    },
    methods: {
        formAccept() {
            this.showAcceptModal = true;
        },
        async handleAcceptModal() {
            this.isDataSending = true;
            if (await this.$api.refundRequests.accept(this.value.id)) {
                await store.dispatch('showSuccessToast', 'Запрос на возврат средств успешно подтвержден').then(() => {});
                this.handleModalCancel();
            }
            this.isDataSending = false;
        },
        handleModalCancel() {
            this.showAcceptModal = false;
            this.isDataSending = false;
            this.$emit('close');
        },
    },
};
</script>
